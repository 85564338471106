import SelectedFilterWrapper from '@/components/Categories/Menu/SelectedFilter'
import { useBooksQuery } from '@/hooks/useBooksQuery'
import { FC } from 'react'
import styled from 'styled-components'
import { findLabel } from '../../../api/booksProducts'

export const BookListSelectedFilter: FC<{ slider?: boolean }> = ({ slider }) => {
	const { selectedFilterList, handleResetFilter, handleDetailFilter } = useBooksQuery()

	if (selectedFilterList.length === 0) return
	return (
		<SelectedFilterWrapper
			position={slider ? 'sideSheet' : 'top'}
			button={<SelectedFilterWrapper.ResetButton handleResetFilter={handleResetFilter} />}>
			{selectedFilterList.map((filter) => (
				<SelectedFilterWrapper.SelectedItem
					key={filter.value}
					label={findLabel(filter.key, filter.value)}
					onRemove={() => handleDetailFilter(filter.key, filter.value, filter.value)}
				/>
			))}
		</SelectedFilterWrapper>
	)
}
export const Wrapper = styled.div`
	position: relative;
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	min-height: 60px;
	height: auto;
	padding: 16px;
	border-radius: 6px;
	background-color: ${(props) => props.theme.new.colors.sol_gray_0};
	margin-top: 20px;

	&.category {
		min-height: 58px;
	}

	.current-area {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 12px;
		height: 100%;

		i {
			margin-left: 4px;
		}
	}

	@media (max-width: 1200px) {
		&.search-page {
			padding: 0 16px;
			/* margin: 0 -16px; */
			width: auto;
		}
	}

	@media (max-width: 768px) {
		padding: 0 16px;
		min-height: unset;
		height: 50px;
		margin: 0 -16px;
		border-radius: 0;
		.current-area {
			flex-wrap: nowrap;
			white-space: nowrap;
			overflow-x: auto;
			height: unset;
			scrollbar-width: thin;
			align-items: center;
		}

		&.slider-type {
			padding: 12px 16px;
			height: auto;
			flex-direction: column;
			align-items: unset;
			margin: 0;
			border-bottom: 1px solid ${(props) => props.theme.new.colors.sol_gray_100};

			.reset-btn {
				justify-content: flex-end;
			}

			.current-area {
				flex-wrap: wrap;
				margin-bottom: 0;
				align-items: flex-end;
			}
		}
	}
`
