import Category from '@/components/Category'
import useGNBHandler from '@/components/common/GNB/useGNBHandler'
import useLogout from '@/components/common/GNB/useLogout'
import { Hamburger, User } from '@/components/icon/IconList'
import { GNBSideSheet } from '@/components/SideSheets/GNBSideSheet'
import { useSideCategoryMenuStore } from '@/components/SideSheets/store/useCategoryMenuStore'
import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import {
	LOGIN_PAGE,
	MYPAGE_COUPONS,
	MYPAGE_PACKAGES_INDEX_PAGE,
	MYPAGE_PASSES,
	MYPAGE_STORES_PRODUCTS_INDEX,
	REGISTRATION_PAGE,
	SOLVOOK_WRITE_STUDIO,
} from '@/settings/constant'
import { CommonGNB, GNB, GNBMenuIcon } from '@bookips/solvook-ui-library'
import { useRouter } from 'next/router'
import { FC, PropsWithChildren, useMemo } from 'react'
import Headroom from 'react-headroom'
import { getCurrentCartQuery } from 'src/api/booksCart'
import { SearchRequestForm } from '../../../containers/Header/SearchRequestForm'

const MarketGNB = () => {
	const { handleLogout, isLoggedIn } = useLogout()

	const { data: cartCounter } = getCurrentCartQuery({
		enabled: isLoggedIn,
		params: {
			cartType: 'separate',
		},
	})

	const {
		handleDropdownClick,
		handleCategoryButton,
		handlePageMove,
		handleSideMenu,
		isOpenSideMenu,
		labName,
	} = useGNBHandler()
	const { isOpenSideMenu: isOpenCategorySideMenu, closeSideMenu } = useSideCategoryMenuStore()

	return (
		<HeadroomWrapper>
			<CommonGNB
				onClickLogo={() => mixpanelEvent('Logo Clicked')}
				isLoggedIn={isLoggedIn}
				loginButtonGroup={{
					onLoginClick: () => handlePageMove(LOGIN_PAGE, true),
					onSignUpClick: () => handlePageMove(REGISTRATION_PAGE, true),
				}}
				category={{
					onClick: handleCategoryButton,
					openCategory: isOpenSideMenu,
				}}
				menu={{
					cart: (path) => handlePageMove(path, false),
					expert: () => handlePageMove(SOLVOOK_WRITE_STUDIO, false),
					library: (path) => handlePageMove(path, false),
					cartBadge: cartCounter?.lineItemsCount,
					fixedMobileMenu: {
						loggedIn: {
							onClick: handleSideMenu,
							icon: <GNBMenuIcon Element={Hamburger} />,
							label: '메뉴',
						},
						loggedOut: {
							onClick: handleSideMenu,
							icon: <GNBMenuIcon Element={Hamburger} />,
							label: '메뉴',
						},
					},
					dropdown: (
						<GNB.MenuButtonWithDropdown
							label="마이페이지"
							menuItems={[
								...(labName
									? [
											{
												label: '나의 연구소',
												onClick: () => handleDropdownClick(`/@${labName}`),
											},
									  ]
									: []),

								{
									label: '패키지 보관함',
									onClick: () => handleDropdownClick(MYPAGE_PACKAGES_INDEX_PAGE),
								},
								{
									label: '쿠폰 관리',
									onClick: () => handleDropdownClick(MYPAGE_COUPONS),
								},
								{
									label: '이용권 관리',
									onClick: () => handleDropdownClick(MYPAGE_PASSES),
								},
								{
									label: '자료 판매',
									onClick: () =>
										handleDropdownClick(MYPAGE_STORES_PRODUCTS_INDEX),
								},
								{
									label: '로그아웃',
									onClick: () => handleLogout(),
								},
							]}>
							<GNBMenuIcon Element={User} />
						</GNB.MenuButtonWithDropdown>
					),
				}}
				searchTextField={<SearchRequestForm />}
			/>
			{isOpenCategorySideMenu && <Category onClose={closeSideMenu} />}
			<GNBSideSheet onLogout={handleLogout} />
		</HeadroomWrapper>
	)
}

export default MarketGNB

const HeadroomWrapper: FC<PropsWithChildren<{}>> = ({ children }) => {
	const { pathname } = useRouter()
	const { isHidden } = useMemo(() => {
		return { isHidden: pathname === '/products/[id]' }
	}, [pathname])

	return isHidden ? (
		<Headroom disable={!isHidden} style={{ zIndex: '800' }}>
			{/* 컨텐츠 중간에 sticky 속성 레이어가 위에 있기에, 부모 요소에서 z-index를 넣어주었습니다. */}
			{children}
		</Headroom>
	) : (
		<>{children}</>
	)
}
