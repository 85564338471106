/**************** WEBAPP CONSTANT (start) **************************/

// General Page Section
export const HOME_PAGE = '/'

// account / login / signup /
export const LOGIN_PAGE = '/member/login'
export const LOGIN_EXIST_KAKAO = '/member/login/exist-kakao-account'
export const LOGIN_EXIST_PHONE = '/member/login/exist-account'
export const LOGIN_WITH_PHONE_PAGE = '/member/login/phone'
export const LOGIN_RESET_PASSWORD_PAGE = '/member/login/reset-password'
export const REGISTRATION_PAGE = '/member/signup'
export const REGISTRATION_WITH_PHONE_PAGE = '/member/signup/phone'
export const FORGOT_USERNAME_PAGE = '/forgot-username'
export const FORGOT_PASSWORD_PAGE = '/forgot-password'
export const FORGOT_PASSWORD_NEW_PAGE = '/member/forgot-password/new'
export const SIGNUP_WITH_PHONE = '/member/signup/form'
export const SIGNUP_AFTER_USER_PROFILE = '/member/signup/profile'
export const SIGNUP_COMPLETE = '/member/signup/complete'
// company
// guides-for-copyright-holder

export const MOBILE_CATEGORY_PAGE = '/categories'
export const INTRODUCE_PAGE = '/introduce'
export const UCI_PAGE = '/protection/uci'
export const RNUMBER_PAGE = '/protection/rNumber'

// todo: 저작권자 영어로 뭐라고 할지?
export const GUIDES_PAGE = '/guides'
export const B2B_INDEX_PAGE = '/b2b'
export const B2B_WEBFLOW_PAGE = 'https://help.solvook.com/b2b'
export const HELP_INDEX_PAGE = '/help'
export const HELP_FAQS_INDEX_PAGE = '/help/faqs'
export const HELP_NOTICES_INDEX_PAGE = '/help/notices'

// Terms
export const TERMS_OF_USE_PAGE = 'https://solvookguide.oopy.io/policies/terms'
export const PRIVACY_PAGE = 'https://solvookguide.oopy.io/policies/privacy'
export const TERMS_OF_REFUND_PAGE = 'https://solvookguide.oopy.io/policies/refund'
export const TERMS_OF_LICENSE_PAGE = '/policies/license'

export const CAREER_PAGE = 'https://www.wanted.co.kr/company/30143'

// protection
export const COPYRIGHT_PROTECTION_INDEX_PAGE = '/protection'
export const COPYRIGHT_PROTECTION_ABOUT_PAGE = '/protection/about'
export const COPYRIGHT_PROTECTION_REPORT_PAGE = '/protection/report'
export const COPYRIGHT_PROTECTION_SEARCH_PAGE = '/protection/search'

// export const

// community
export const COMMUNITY_INDEX_PAGE = '/community'
export const COMMUNITY_SHOW_PAGE = '/community/'

// collection
export const COLLECTION_SLUG_LIST_PAGE = '/collections/'

// category
export const CATEGORY_PAGE = '/categories'
//Fixme : 과거 어딘가에서 사용중이어서 삭제하지 않음
export const CATEGORY_TEXTBOOK_PAGE = '/category/textbook'
export const CATEGORY_WORKBOOK_PAGE = '/category/workbook'
export const CATEGORY_HANDOUT_PAGE = '/category/handout'
export const CATEGORY_WORK_PAGE = '/category/work'

// products
// resources
export const PRODUCTS_PAGE = '/products'

//search
export const PRODUCT_SEARCH_PAGE = '/search'

//@todo 임시 url
export const PRODUCTS_TEXTBOOK_PAGE = '/products?category=textbook'
// export const PRODUCTS_TEXTBOOK_PAGE = '/products/textbook'
export const PRODUCTS_WORKBOOK_PAGE = '/products?category=workbook'
// export const PRODUCTS_WORKBOOK_PAGE = '/products/workbook'
// export const PRODUCTS_HANDOUT_PAGE = '/products/handout'
export const PRODUCTS_HANDOUT_PAGE = '/products?category=handout'

export const PRODUCTS_DETAIL_PAGE = '/products/'

// shop
export const SHOP_CART_PAGE = '/shop/cart'
export const SHOP_CHECKOUT_PAGE = '/shop/checkout'
export const SHOP_CHECKOUT_COMPLETE_PAGE = '/shop/checkout/complete'
export const SHOP_CHECKOUT_UPGRADE_PAGE = '/shop/checkout?upgrade'
export const SHOP_ORDERS_PAGE = '/shop/orders'
export const SHOP_ORDERS_DETAIL_PAGE = '/shop/orders/' // /shop/orders/:id
export const SHOP_ORDERS_COMPLETE_PAGE = '/shop/orders/complete' //shop/orders/complete

export const NEW_PRODUCTS_PAGE = '/search' //검색

export const B2B_LANDING_PAGE = '/b2b'

export const LIBRARIES_INDEX_PAGE = '/libraries' // 라이브러리

export const CLASS_INDEX_PAGE = '/class'

export const MYPAGE_PAGE = '/mypage' // 마이페이지 메인
// mySolvook Tab
export const MYPAGE_FREEPRASSES_INDEX_PAGE = '/mypage/freepasses' // 프리패스
export const MYPAGE_LICENSES_INDEX_PAGE = '/mypage/licenses' // 구독관리
export const MYPAGE_LICENSES_DETAIL_INDEX_PAGE = '/mypage/licenses/' // 구독관리
export const MYPAGE_ORDERS_INDEX_PAGE = '/mypage/orders' // 결제관리
export const MYPAGE_ORDERS_DETAIL_INDEX_PAGE = '/mypage/orders/' // 결제관리
export const MYPAGE_ACCOUNTS_INDEX_PAGE = '/mypage/accounts' // 계정관리
export const MYPAGE_STUDIO_INDEX_PAGE = '/mypage/studio' // 계정관리
export const MYPAGE_DEVICE_INDEX_PAGE = '/mypage/devices'
export const MYPAGE_PACKAGES_INDEX_PAGE = '/mypage/packages'
// Store Tab
export const MYPAGE_STORES_INDEX = '/mypage/stores' // 스토어
export const MYPAGE_STORES_PRODUCTS_INDEX = '/mypage/stores/products' // 스토어:상품 조회
export const MYPAGE_STORES_PRODUCTS_NEW_INDEX = '/mypage/stores/products/new' // 스토어:상품 등록
export const MYPAGE_STORES_PRODUCTS_EDIT_INDEX = '/mypage/stores/products/:id/edit' // 스토어:상품 등록
export const MYPAGE_STORES_PAYOUT_INDEX = '/mypage/stores/payouts' // 스토어:정산관리
export const MYPAGE_STORES_UCI_INDEX = '/mypage/stores/uci' // 스토어:uci 관리

// Viewer Page

export const VIEWER_PAGE = '/viewer'
export const VIEWER_PRINTOUT_PAGE = '/viewer/printout'

// export const MYPAGE_MY_PRODUCTS = '/mypage/products'
// export const MYPAGE_HISTORY_PAGE = '/mypage/histories'
// export const MYPAGE_PRODUCTS_INDEX = '/mypage/stores/products' //자료관리
// export const MYPAGE_PAYOUTS_INDEX = '/mypage/payouts' //정산관리

// Lab Page
export const LAB_PAGE = (authorId) => {
	return `/@${authorId}`
}
export const LAB_NOTICE_PAGE = (authorId) => {
	return `/@${authorId}/notices`
}
export const LAB_QNA_PAGE = (authorId) => {
	return `/@${authorId}/qna`
}
export const LAB_INFO_PAGE = (authorId) => {
	return `/@${authorId}/info`
}
export const LAB_REWRITE_PAGE = '/labs/[author]/home'
export const LAB_NOTICE_REWRITE_PAGE = '/labs/[author]/notices'
export const LAB_NOTICE_NEW_REWRITE_PAGE = '/labs/[author]/notices/new'
export const LAB_QNA_REWRITE_PAGE = '/labs/[author]/qna'
export const LAB_INFO_REWRITE_PAGE = '/labs/[author]/info'

/**************** WEBAPP CONSTANT (end) **************************/

/**************** ADMIN CONSTANT (start) **************************/

// Admin Page
export const ADMIN_PAGE_INDEX = '/admin'
export const ADMIN_PAGE_LOGIN = '/admin/login'
export const ADMIN_PAGE_HANDOUTS_INDEX = '/admin/handouts'
export const ADMIN_PAGE_HANDOUTS_NEW_PAGE = '/admin/handouts/new'
export const ADMIN_PAGE_HANDOUTS_EDIT_PAGE = '/admin/handouts/:id/edit'
export const ADMIN_PAGE_WORKBOOKS_INDEX = '/admin/workbooks'
export const ADMIN_PAGE_WORKBOOKS_NEW_PAGE = '/admin/workbooks/new'
export const ADMIN_PAGE_WORKBOOKS_EDIT_PAGE = '/admin/workbooks/:id/edit'
export const ADMIN_PAGE_TEXTBOOKS_INDEX = '/admin/textbooks'
export const ADMIN_PAGE_TEXTBOOKS_NEW_PAGE = '/admin/textbooks/new'
export const ADMIN_PAGE_TEXTBOOKS_EDIT_PAGE = '/admin/textbooks/:id/edit'
export const ADMIN_PAGE_ORDERS_EDIT_PAGE = '/admin/orders'
export const ADMIN_PAGE_ORDERS_DETAIL_PAGE = '/admin/orders/:id'
export const ADMIN_PAGE_PROTECTS_INDEX = '/admin/protects'
export const ADMIN_PAGE_USER_INDEX = '/admin/users'
export const ADMIN_PAGE_USER_DETAIL_PAGE = '/admin/users/:id'
export const ADMIN_PAGE_BANNERS_INDEX = '/admin/banners'
export const ADMIN_PAGE_POLICIES_INDEX = '/admin/policies'

/**************** ADMIN CONSTANT (end) **************************/

/**************** PUBLISHER CONSTANT (start) **************************/

export const PUBLISHER_LOGIN = '/publisher/login'

export const PUBLISHER_DASHBOARD = '/publisher/dashboard'
export const PUBLISHER_PRODUCT = '/publisher/product'
export const PUBLISHER_SALES_TEXTBOOK = '/publisher/sales/textbook'
export const PUBLISHER_SALES_HANDOUT = '/publisher/sales/handout'
export const PUBLISHER_PROTECT = '/publisher/protect'

/**************** PUBLISHER CONSTANT (end) **************************/

export const DEFAULT_BOOK_COVER = '/static/assets/img_book_def.jpg'

/**************** PROMOTION CONSTANT (end) **************************/
export const PROMOTION_AUTHOR_PAGE = 'https://promotion.solvook.com/promotions/consulting2/home'
export const PROMOTION_EBOOK_PAGE = 'https://promotion.solvook.com/promotions/ebook'
/**************** HELP CENTER CONSTANT (end) **************************/
export const HELP_CENTER_PAGE = 'https://help.solvook.com'
/**************** EXTERNAL LINK CONSTANT (end) **************************/
export const EXTERNAL_COMPANY_INTRODUCE = 'https://solvookguide.oopy.io/company'
export const EXTERNAL_PROTECTION_GUIDE = 'https://solvookguide.oopy.io/copyright'

export const EXTERNAL_SOLVOOK_NOTE_GUIDE = 'https://solvookguide.oopy.io/guide/5'
export const EXTERNAL_CHANNEL_TALK = 'https://solvook.channel.io/home'
export const EXTERNAL_CAHNNEL_TALK_BOT = 'https://solvook.channel.io/support-bots/92860'
export const EXTERNAL_SOLVOOK_GUIDE = 'https://solvookguide.oopy.io/'
export const EXTERNAL_SOLVOOK_BLOG = 'https://blog.naver.com/bookips'
export const EXTERNAL_SOLVOOK_POST = 'https://post.naver.com/my.naver?memberNo=55570568'
export const EXTERNAL_B2B_WEBFLOW_PAGE = 'http://biz.solvook.com'
export const EXTERNAL_PROMOTION_AUTHOR_PAGE = 'https://promotion.solvook.com/apply/author'
export const EXTERNAL_PROMOTION_EBOOK_PAGE = 'https://promotion.solvook.com/promotions/ebook'
export const EXTERNAL_CAREER_PAGE = 'https://www.wanted.co.kr/company/30143'
export const EXTERNAL_IR_PAGE = 'https://solvook.channel.io/support-bots/92681'
export const EXTERNAL_INTRODUCE_MARKET = 'https://solvookguide.oopy.io/guide/13'
export const EXTERNAL_INTRODUCE_STUDIO = 'https://solvookguide.oopy.io/guide/12'
export const EXTERNAL_INTRODUCE_LIBRARY = 'https://solvookguide.oopy.io/guide/11'
export const EXTERNAL_INTRODUCE_NOTE = 'https://solvookguide.oopy.io/guide/10'
export const EXTERNAL_SOLVOOK_MEMBERSHIP_PROMOTION =
	'https://promotion.solvook.com/promotions/membership'
export const EXTERNAL_DEVICE_GUIDE = 'https://solvookguide.oopy.io/guide/device'
export const BOOKS_LANDING = '/books'
export const BOOKS_ORDER = '/books/order'
export const BOOKS_PRODUCT = '/books/products'
export const BOOKS_DELIVERY_ADDRESS = '/books/delivery-address'

export const EBOOKS_LANDING = '/ebooks'
export const EBOOKS_ORDER = '/ebooks/order'
export const EBOOKS_PRODUCT = '/ebooks/products'

export const EBOOKS_UNIVERSITY_LANDING = '/goodnotes-univ' //대학생용 ebook 메인 페이지
export const EBOOKS_UNIVERSITY_PRODUCT = '/goodnotes-univ/products'
export const EBOOKS_UNIVERSITY_ORDER = '/goodnotes-univ/order'

export const PASSES_LANDING = '/passes' //이용권 페이지
export const PASSES_ORDER = '/passes/order' //이용권 구매
export const PASSES_NEW = '/passes/new' //이용권 등록
export const MYPAGE_PASSES = '/mypage/passes' //이용권 관리

export const SUBSCRIBE_PAYMENTS = '/subscribe' //정기결제 신청
export const SUBSCRIBE_CANCEL = '/subscribe/checkCancel' //정기결제 해지
export const PROMOTION_MEGASTUDY = 'promotion/megastudy'

export const SOLVOOK_WRITE_STUDIO = process.env.NEXT_PUBLIC_STUDIO_URL + '/handouts' //저작스튜디오
export const SOLVOOK_EDIT_STUDIO = process.env.NEXT_PUBLIC_EDIT_STUDIO_URL + '/layout' //편집스튜디오
export const EXPERT_PRE_EXAM = process.env.NEXT_PUBLIC_EDIT_STUDIO_URL + '/handouts/pre-exam' //엑스퍼트 우리학교 기출 경로

export const REVIEWS_NEW = '/reviews/new'
export const COUPONS_LANDING = '/coupons'

export const MYPAGE_COUPONS = '/mypage/coupons' //쿠폰 관리
export const STUDIO_TITLE = '학습지 편집'

export const PACKAGE_ORDER = '/package/order'
export const PACKAGE_ORDER_COMPLETE = '/package/complete'
export const EXTERNAL_PACKAGE_CAMPAIGN = 'https://campaign.solvook.com/package/products'
