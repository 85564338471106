import { useSideCategoryMenuStore } from '@/components/SideSheets/store/useCategoryMenuStore'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useMemo, useState } from 'react'
import { Category, getCategoryMenu, GetCategoryResponse } from 'src/api/category'

export type DepthItem = {
	origin: GetCategoryResponse
	two: Category
	three: Category
	four: Category
	currentDepth?: string
}

const useCategoryPanelData = () => {
	//
	const [oneDepthId, setOneDepthId] = useState('') //과목 1depth
	const [twoDepthId, setTwoDepthId] = useState('') //단계 2depth
	const [threeDepthId, setThreeDepthId] = useState('') //상세 3depth

	const { data: categoryData } = useQuery(['categoryData'], () => getCategoryMenu(), {
		retry: 3,
	})

	const { currentOneDepth, setCurrentDepth } = useSideCategoryMenuStore((state) => ({
		currentOneDepth: state.currentDepth,
		setCurrentDepth: state.setCurrentDepth,
	}))

	const selectTwoDepth = useMemo(() => {
		if (!oneDepthId) return

		const data = categoryData.depthItems.filter((menu, idx) => menu.id === oneDepthId)
		setTwoDepthId(data[0].items[0].id)
		return data[0]
	}, [oneDepthId])

	const selectThreeDepth = useMemo(() => {
		if (!twoDepthId) return
		const data = selectTwoDepth.items.filter((menu) => menu.id === twoDepthId)

		setThreeDepthId(data[0].items[0].id)

		return data[0]
	}, [twoDepthId])

	const selectFourDepth = useMemo(() => {
		if (!threeDepthId) return
		const data = selectThreeDepth.items.filter((menu) => menu.id === threeDepthId)[0]
		return data
	}, [threeDepthId])

	useEffect(() => {
		if (!categoryData) return

		if (!currentOneDepth) {
			setCurrentDepth(categoryData.selectedId)
		}

		setOneDepthId(currentOneDepth ? currentOneDepth : categoryData.selectedId)

		const selectedTwoDepth = categoryData.depthItems.filter((subject) => {
			if (currentOneDepth) return subject.id === currentOneDepth

			return subject.id === categoryData.selectedId
		})
		setTwoDepthId(selectedTwoDepth[0].items[0].id)
	}, [categoryData, currentOneDepth])

	return {
		oneDepthId,
		setOneDepthId,
		twoDepthId,
		setTwoDepthId,
		threeDepthId,
		setThreeDepthId,

		depthData: {
			origin: categoryData,
			two: selectTwoDepth,

			three: selectThreeDepth,
			four: selectFourDepth,
		} as DepthItem,
	}
}

export default useCategoryPanelData
