import useGNBHandler from '@/components/common/GNB/useGNBHandler'
import useLogout from '@/components/common/GNB/useLogout'
import { User } from '@/components/icon/IconList'
import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import { LOGIN_PAGE, MYPAGE_PASSES, REGISTRATION_PAGE } from '@/settings/constant'
import { CommonGNB, GNBMenuIcon } from '@bookips/solvook-ui-library'
import { getCurrentCartQuery } from 'src/api/booksCart'

const ExpertGNB = () => {
	const { isLoggedIn } = useLogout()

	const { data: cartCounter } = getCurrentCartQuery({
		params: {
			cartType: 'separate',
		},
	})

	const { handlePageMove } = useGNBHandler()

	return (
		<CommonGNB
			onClickLogo={() => mixpanelEvent('Logo Clicked')}
			isLoggedIn={isLoggedIn}
			loginButtonGroup={{
				onLoginClick: () => handlePageMove(LOGIN_PAGE, true),
				onSignUpClick: () => handlePageMove(REGISTRATION_PAGE, true),
			}}
			menu={{
				cart: (path) => handlePageMove(path, false),
				fixedMobileMenu: {
					loggedIn: {
						onClick: () => handlePageMove(MYPAGE_PASSES, false),
						icon: <GNBMenuIcon Element={User} />,
						label: '마이페이지',
					},
					loggedOut: {
						onClick: () => handlePageMove(REGISTRATION_PAGE, false),
						icon: <GNBMenuIcon Element={User} />,
						label: '마이페이지',
					},
				},
				cartBadge: cartCounter?.lineItemsCount,
				mypage: () => handlePageMove(isLoggedIn ? MYPAGE_PASSES : REGISTRATION_PAGE, false),
			}}
			searchTextField={false}
			banner="expert"
			brandSiblings="expert"
			disabledCategory={true}
		/>
	)
}
export default ExpertGNB
