import { IQueryResponse, otherApiClient, PaginationQuery } from '.'
import {
	Categories,
	CategoriesWithString,
	ContentTypes,
	ContentTypesWithString,
	Curriculums,
	CurriculumsWithString,
	Grades,
	Subjects,
	SubjectsWithString,
} from './product'

export interface IFilterSpecific<T, F> {
	id: T
	value: F
	count?: number
}

export interface Pagination {
	offset: number
	limit: number
	total: number
}

export interface Category {
	id: number
	depth: number
	parent_id?: number
	name: string
	short_name?: string
	keywords?: string[]
	product_cnt?: number
	order: number
	options?: {
		[key: string]: any
	}
	create_at: Date
	update_at: Date
}

export const enum SearchType {
	ALL = 'all',
	TEXTBOOK = 'textbook',
	WORKBOOK = 'workbook',
	HANDOUT = 'handout',
	WORK = 'work',
}

export interface filterType {
	title: string
	id: number
}

export const defaultSearchType = SearchType.ALL

export interface DetailFilter {
	filter_parsing?: boolean // 편집가능 필터 사용여부
	brands?: {
		initial: string
		values: IFilterSpecific<number, string>[]
	}[]
	units?: IFilterSpecific<number, string>[]
	publishers?: IFilterSpecific<number, string>[]
	data_types?: IFilterSpecific<Categories, CategoriesWithString>[] // 30,40,50
	curriculums?: IFilterSpecific<Curriculums, CurriculumsWithString>[]

	subjects?: IFilterSpecific<Subjects, SubjectsWithString>[]

	content_types?: IFilterSpecific<ContentTypes, ContentTypesWithString>[]

	grades?: Grades[]
}

export type SearchQueryKeys = typeof SearchQueryKey[keyof typeof SearchQueryKey]

export const searchQueryKeys: SearchQueryKeys[] = [
	'contentTypes',
	'dataTypes',
	'authors',
	'units',
	'editables',
	'publishers',
	'curriculums',
	'genres',
	'eras',
	'relatedBooks',
	'grades',
	'tickets',
]
export const sortKeys: Array<SortType> = ['POPULAR', 'LATEST', 'TITLE', 'PRICE_ASC']

export interface IGetDetailFilterRes extends IQueryResponse {
	handout: Omit<BaseFilter, 'curriculums' | 'publishers'>
	textbook: Pick<BaseFilter, 'curriculums' | 'publishers'>
	workbook: Pick<BaseFilter, 'curriculums' | 'publishers'>
	work: Pick<BaseFilter, 'genres' | 'eras' | 'relatedBooks'>
}

export const SortType = {
	POPULAR: '인기순',
	LATEST: '최신순',
	PRICE_ASC: '낮은가격순',
	TITLE: '이름순',
} as const
export type SortType = keyof typeof SortType
export const SearchQueryKey = {
	PRODUCT_TYPE: 'productType', // 상품 타입- 알라딘, 굿노트
	KEYWORD: 'q', // 검색어
	SORT: 'sort', // 정렬 키
	SUBJECT: 'subject', // 과목
	CURRICULUMS: 'curriculums', // 교육 과정
	PUBLISHERS: 'publishers', // 출판사
	GRADES: 'grades', // 학년
	CONTENTTYPES: 'contentTypes',
	DATATYPES: 'dataTypes',
	UNITS: 'units',
	EDITABLES: 'editables',
	AUTHORS: 'authors',
	GENRES: 'genres',
	ERAS: 'eras',
	RELATEDBOOKS: 'relatedBooks',
	TICKETS: 'tickets', //이용권
} as const

type SearchQueryKeyType = typeof SearchQueryKey[keyof typeof SearchQueryKey]
export type SearchFilters = {
	[key in Exclude<SearchQueryKeyType, 'EDITABLES'>]?: Array<string>
} & {
	editable?: string
}

export interface IGetSearchProductRequest extends PaginationQuery {
	filters?: SearchFilters
	productType?: SearchType
	q?: string
	sort?: SortType
	available?: boolean
	subject?: string
	offset?: number
	limit?: number
}

interface BaseAttributes {
	subject: Subjects
	relatedBooks?: Array<string>
}

export interface FilterObject {
	id: number
	value: string
}
export interface BaseFilter {
	contentTypes?: Array<string>
	dataTypes?: Array<string>
	authors?: Array<string>
	units?: Array<string>
	editables?: Array<string>
	publishers?: Array<string>
	curriculums?: Array<string>
	genres?: Array<string>
	eras?: Array<string>
	relatedBooks?: Array<string>
	grades?: Array<string>
	tickets?: Array<FilterObject>
}

export const BaseFilterKeyToLabel = {
	contentTypes: '학습 내용',
	dataTypes: '자료 유형',
	authors: '제작자',
	units: '단원',
	editables: '편집 가능',
	publishers: '출판사',
	curriculums: '교육 과정',
	genres: '장르',
	eras: '시대',
	relatedBooks: '교재',
	grades: '학년',
	tickets: '이용권',
} as const

// 추가 공통 타입 정의
interface HandoutBaseAttributes extends BaseAttributes {
	unit: string
	author: string
	editable: boolean
	dataTypes: Array<Categories>
	questionNo: number
	contentTypes: Array<ContentTypes>
}

interface BookBaseAttributes extends BaseAttributes {
	grade: Grades
	publisher: string
	curriculum: string
}

interface WorkBaseAttributes extends BaseAttributes {
	genre: string
	era: string
}

export interface SearchProductItem {
	productId: number
	productType: string
	title: string
	available: boolean
	isCouponAvailable: boolean
	price: number
	thumbImg: string
	avgReviewRating: string
	orderCount: number
	attributes: {
		handout?: HandoutBaseAttributes
		book?: BookBaseAttributes
		work?: WorkBaseAttributes
	}
	tags: Array<string>
	registeredAt: string
}

export interface IGetSearchProductsRes extends IQueryResponse {
	pagination: Pagination
	resource: string
	data: SearchProductItem[]
}

const filterValidFilters = (filters: { [key: string]: unknown }): { [key: string]: unknown } => {
	return Object.entries(filters).reduce((acc, [key, value]) => {
		if (Array.isArray(value) ? value.length > 0 : value) {
			acc[key] = value
		}
		return acc
	}, {} as any)
}
export const getSearchFilter = async (query: IGetSearchProductRequest) => {
	const { filters, productType, ...rest } = query
	const validFilters = filters ? filterValidFilters(filters) : undefined
	const { data } = await otherApiClient.market.get<IGetDetailFilterRes>(`/search/filter`, {
		params: {
			...rest,
			productType: productType,
			...(validFilters ? { filter: JSON.stringify({ [productType]: validFilters }) } : null),
		},
	})
	return data
}
export const getSearchProducts = async (query: IGetSearchProductRequest) => {
	const { filters, productType, ...rest } = query

	const { data } = await otherApiClient.market.get<IGetSearchProductsRes>(`/search`, {
		params: {
			...rest,
			productType: productType,
			filter: JSON.stringify({
				[productType]: filterValidFilters(filters),
			}),
		},
	})
	return data
}

export interface TermItem {
	subject: Subjects
	placeHolder: string
	terms: Array<string>
}

export interface IGetSearchTermResponse {
	termItems: Array<TermItem>
}

export const getSearchTerms = async () => {
	const { data } = await otherApiClient.market.get<IGetSearchTermResponse>(`/search/terms`, {})
	return data
}
