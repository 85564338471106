import { Wrapper } from '@/components/Books/List/BookListSelectedFilter'
import React, { FC, ReactNode } from 'react'
import styled, { useTheme } from 'styled-components'
import { Close, Reset, Sizes } from '@/components/icon/IconList'
import Icon from '@/components/icon/Icon'
import { themeGet } from '@styled-system/theme-get'
import { Button } from 'antd'

export const SelectedFilterWrapper = ({
	position,
	children,
	button,
	className,
}: {
	position?: string
	children?: ReactNode
	button?: ReactNode
	className?: string
}) => {
	return (
		<Wrapper className={position === `sideSheet` ? `slider-type ${className}` : className}>
			<div className={'current-area'}>{children}</div>
			{button}
		</Wrapper>
	)
}
export const ResetButton: FC<{ handleResetFilter: () => void }> = ({ handleResetFilter }) => {
	const theme = useTheme()
	return (
		<ResetButtonWrapper className={'reset-btn'} onClick={handleResetFilter}>
			<Icon icon={Reset} color={theme.new.colors.sol_gray_600} size={Sizes.small} />
			초기화
		</ResetButtonWrapper>
	)
}
export const SelectedItem: FC<{ label: ReactNode; onRemove: () => void; className?: string }> = ({
	label,
	onRemove,
	className,
}) => {
	return (
		<SelectedItemWrapper onClick={onRemove} className={className || 'current-btn'}>
			{label}
			<Icon icon={Close} size={Sizes.small} color={'#fff'} />
		</SelectedItemWrapper>
	)
}

SelectedFilterWrapper.ResetButton = ResetButton
SelectedFilterWrapper.SelectedItem = SelectedItem
export default SelectedFilterWrapper

export const CategorySelectedItem = styled(SelectedItem)`
	font-size: 14px;
`

const ResetButtonWrapper = styled(Button)`
	&.reset-btn {
		height: 26px;
		padding: 2px 0;
		display: flex;
		align-items: center;
		border: none;
		background-color: transparent;

		i {
			margin-right: 4px;
		}

		span {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 4px;
			color: ${(props) => props.theme.new.colors.sol_gray_600};
			font-weight: bold;
			transition: color 0.2s linear;

			@media (hover: hover) {
				&:hover {
					color: ${themeGet('text.0', '#1B1B29')};
				}
			}
		}

		@media (max-width: 768px) {
			padding: 0;
			margin-left: 8px;
			color: ${(props) => props.theme.new.colors.sol_gray_500};
		}
	}
`

const SelectedItemWrapper = styled.div`
	&.current-btn {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;
		padding: 2px 12px;
		line-height: 22px;
		font-weight: 600;
		border: none;
		height: 26px;
		background-color: ${(props) => props.theme.new.colors.sol_gray_500};
		border-radius: 36px;
		cursor: pointer;
		font-size: 14px;
		@media (max-width: 1200px) {
		}
		@media (max-width: 768px) {
			/* padding: 2px 8px; */
			height: 26px;
			font-weight: 400;
		}
	}

	&.category {
		font-size: 14px;
	}
`
