import { useSideCategoryMenuStore } from '@/components/SideSheets/store/useCategoryMenuStore'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useMemo } from 'react'
import { getCategoryMenu } from 'src/api/category'
import { DepthItem } from './useCategoryPanelData'

const useCategorySideDepth = () => {
	const currentOneDepth = useSideCategoryMenuStore((state) => state.currentDepth)
	const twoDepthIdInStore = useSideCategoryMenuStore((state) => state.twoDepthId)
	const threeDepthIdInStore = useSideCategoryMenuStore((state) => state.threeDepthId)
	const setState = useSideCategoryMenuStore((state) => state.setState)

	const { data: categoryData } = useQuery(['categoryData'], () => getCategoryMenu(), {
		retry: 3,
	})

	// oneDepth 선택시 2depth 선택
	const selectTwoDepth = useMemo(() => {
		if (!categoryData) return
		if (!currentOneDepth) return

		return categoryData.depthItems.filter((menu, idx) => menu.id === currentOneDepth)[0]
	}, [categoryData, currentOneDepth])

	// 2depth 선택시 3depth 선택
	const selectThreeDepth = useMemo(() => {
		if (!twoDepthIdInStore) return

		return selectTwoDepth.items.filter((menu) => {
			if (twoDepthIdInStore) {
				return menu.id === twoDepthIdInStore
			}
		})[0]
	}, [selectTwoDepth, twoDepthIdInStore])

	// 3depth 선택시 4depth 선택
	const selectFourDepth = useMemo(() => {
		if (!selectThreeDepth) return

		return selectThreeDepth.items.filter((menu) => menu.id === threeDepthIdInStore)[0]
	}, [threeDepthIdInStore])

	useEffect(() => {
		if (selectThreeDepth) {
			if (!threeDepthIdInStore) {
				setState('threeDepthId', selectThreeDepth.items[0].id)
			}
		}
	}, [selectThreeDepth, threeDepthIdInStore, setState])

	useEffect(() => {
		if (!categoryData) return
		if (!currentOneDepth) {
			setState('currentDepth', categoryData.selectedId)
		}
	}, [categoryData, currentOneDepth, setState])

	return {
		currentOneDepth,
		depthData: {
			origin: categoryData,
			two: selectTwoDepth,
			three: selectThreeDepth,
			four: selectFourDepth,
		} as DepthItem,
	} as const
}

export default useCategorySideDepth
