import { LayoutContainer } from '@/containers/Layout/Layout'
import { GlobalConfigProvider } from '@/hooks/useConfig'
import { AuthInfo, AuthProvider } from '@/lib/auth/AuthStore'
import { GA_TRACKING_ID, gaPageView } from '@/lib/ga4'
import { AppDefaultStyle } from '@/themes/defaultStyle'
import { GlobalStyles } from '@/themes/Global.style'
import { ConfigProvider } from 'antd'
import 'antd/dist/reset.css' // import '../themes/antd-custom.less'
import type { AppProps as NextAppProps } from 'next/app'
import App from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { ReactElement, ReactNode, useEffect } from 'react'
import { StyleSheetManager, ThemeProvider } from 'styled-components'
import { default as themes } from '../themes'

import apiService from '@/lib/apiService/apiService'
import { RestfulApiProvider } from '@/lib/apiService/RestfulApiService'
import uiLibraryTheme from '@/themes/uiLibraryTheme'
import { CssBaseline, ThemeProvider as MuiThemeProvider } from '@mui/material'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { getCookies } from 'cookies-next'
import { NextPage } from 'next'
import { DefaultSeo } from 'next-seo'
import { isInWebview } from 'src/utils/misc'
import { getMe } from '../api/getMe'
import defaultSeoConfig from '../settings/next-seo.config'
import { solvookPageViewed } from '@/lib/mixpanels/mixpanel'

const KAKAO_JAVASCRIPT_SDK_KEY = process.env.NEXT_PUBLIC_KAKAO_JAVASCRIPT_SDK_KEY
const Mixpanel_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN
const GOOGLE_TAG_MANAGER_ID = isInWebview() || process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID

type AppProps = NextAppProps & {
	auth?: AuthInfo
}
const queryClient = new QueryClient()
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
	getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout
}
const MyApp = ({ Component, pageProps, auth }: AppPropsWithLayout) => {
	const currentSelectedTheme = 'defaultTheme'
	const currentTheme = themes[currentSelectedTheme]

	const router = useRouter()
	const asPath = router.asPath

	useEffect(() => {
		try {
			if (!window.Kakao.isInitialized() && window.Kakao) {
				// JavaScript key를 인자로 주고 SDK 초기화
				window.Kakao.init(KAKAO_JAVASCRIPT_SDK_KEY)
				// SDK 초기화 여부를 확인하자.
				console.log('window.Kakao.isInitialized()', window.Kakao.isInitialized())
				// window.Kakao.init(process.env.NEXT_PUBLIC_KAKAO_JAVASCRIPT)
			}
		} catch (e) {
			console.log(e)
		}
	}, [])
	useEffect(() => {
		gaPageView(asPath)
		solvookPageViewed(asPath)
	}, [asPath])
	const getLayout = Component.getLayout ?? ((page) => <LayoutContainer>{page}</LayoutContainer>)
	const isSignupPage =
		router.pathname.includes('/signup') || router.pathname.includes('/checkout')
	const jsonLdData = {
		'@context': 'http://schema.org',
		'@type': 'Organization',
		name: '쏠북',
		url: 'https://solvook.com',
		sameAs: ['https://blog.naver.com/bookips'],
	}
	return (
		<>
			<Head>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
				/>
				<link
					rel="stylesheet"
					type="text/css"
					href="https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css"
				/>
				<link rel="preconnect" href="https://fonts.googleapis.com" />
				<link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin={'anonymous'} />
				<link
					href="https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap"
					rel="stylesheet"
				/>

				<script
					type="application/ld+json"
					dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdData) }}
				/>
			</Head>
			{/*//Fixme 테스트 후 제거 */}
			{/*{pageProps?.metadata ? (*/}
			{/*	<NextSeo*/}
			{/*		{...pageProps.metadata}*/}
			{/*		{...(process.env.NEXT_PUBLIC_ENV !== 'production' && {*/}
			{/*			dangerouslySetAllPagesToNoIndex: true,*/}
			{/*			dangerouslySetAllPagesToNoFollow: true,*/}
			{/*		})}*/}
			{/*	/>*/}
			{/*) : (*/}
			<DefaultSeo
				{...defaultSeoConfig}
				{...(process.env.NEXT_PUBLIC_ENV !== 'production' && {
					dangerouslySetAllPagesToNoIndex: true,
					dangerouslySetAllPagesToNoFollow: true,
				})}
			/>
			{/*)}*/}

			{/*<AppDefaultStyle />*/}
			<GlobalConfigProvider>
				<AuthProvider initialValues={auth}>
					<RestfulApiProvider value={apiService}>
						<ThemeProvider theme={currentTheme}>
							<StyleSheetManager disableVendorPrefixes>
								<ConfigProvider
									theme={{
										token: {
											colorPrimary: currentTheme.primary[0],
											colorPrimaryText: currentTheme.text[0],
											borderRadius: 2,
										},
										components: {
											Button: {
												colorPrimary: currentTheme.primary[0],
											},
										},
									}}>
									<QueryClientProvider client={queryClient}>
										<>
											<GlobalStyles />
											<AppDefaultStyle />
											<MuiThemeProvider theme={uiLibraryTheme}>
												<CssBaseline />
												{getLayout(<Component {...pageProps} />)}
											</MuiThemeProvider>
											<ReactQueryDevtools initialIsOpen={true} />
											<div id="overlay" />
										</>
									</QueryClientProvider>
								</ConfigProvider>
							</StyleSheetManager>
						</ThemeProvider>
					</RestfulApiProvider>
				</AuthProvider>
			</GlobalConfigProvider>

			{/*<Script*/}
			{/*	src="https://t1.kakaocdn.net/kakao_js_sdk/2.1.0/kakao.min.js"*/}
			{/*	integrity="sha384-dpu02ieKC6NUeKFoGMOKz6102CLEWi9+5RQjWSV0ikYSFFd8M3Wp2reIcquJOemx"*/}
			{/*	crossOrigin="anonymous"*/}
			{/*	onLoad={kakaoInit}*/}
			{/*/>*/}
			{/* Global Site Tag (gtag.js) - Google Analytics */}
			<Script
				id={'gtag-script'}
				strategy="afterInteractive"
				src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
			/>
			<Script
				id={'gtag-init-script'}
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
					__html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
				}}
			/>
			{/*<Script src="bower_components/mixpanel/mixpanel-jslib-snippet.min.js"></Script>*/}
			{typeof window != 'undefined' && !(window as any).mixpanel && (
				<Script
					id={'mixpanel-init-script'}
					strategy="afterInteractive"
					dangerouslySetInnerHTML={{
						__html: `
              (function(f,b){if(!b.__SV){var e,g,i,h;window.mixpanel=b;b._i=[];b.init=function(e,f,c){function g(a,d){var b=d.split(".");2==b.length&&(a=a[b[0]],d=b[1]);a[d]=function(){a.push([d].concat(Array.prototype.slice.call(arguments,0)))}}var a=b;"undefined"!==typeof c?a=b[c]=[]:c="mixpanel";a.people=a.people||[];a.toString=function(a){var d="mixpanel";"mixpanel"!==c&&(d+="."+c);a||(d+=" (stub)");return d};a.people.toString=function(){return a.toString(1)+".people (stub)"};i="disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking start_batch_senders people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(" ");
            for(h=0;h<i.length;h++)g(a,i[h]);var j="set set_once union unset remove delete".split(" ");a.get_group=function(){function b(c){d[c]=function(){call2_args=arguments;call2=[c].concat(Array.prototype.slice.call(call2_args,0));a.push([e,call2])}}for(var d={},e=["get_group"].concat(Array.prototype.slice.call(arguments,0)),c=0;c<j.length;c++)b(j[c]);return d};b._i.push([e,f,c])};b.__SV=1.2;e=f.createElement("script");e.type="text/javascript";e.async=!0;e.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?
            MIXPANEL_CUSTOM_LIB_URL:"file:"===f.location.protocol&&"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\\/\\//)?"https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";g=f.getElementsByTagName("script")[0];g.parentNode.insertBefore(e,g)}})(document,window.mixpanel||[]);
            mixpanel.init('${Mixpanel_TOKEN}', {debug: true, track_pageview: "full-url"});
          
  `,
					}}
				/>
			)}
			<noscript>
				<iframe
					src={`https://www.googletagmanager.com/ns.html?id=${GOOGLE_TAG_MANAGER_ID}`}
					height="0"
					width="0"
					style={{ display: 'none', visibility: 'hidden' }}
				/>
			</noscript>
			<Script
				id="google-tag-manager"
				dangerouslySetInnerHTML={{
					__html: `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer', '${GOOGLE_TAG_MANAGER_ID}');
        `,
				}}
			/>
			<Script type="text/javascript" src="//wcs.naver.net/wcslog.js"></Script>
			{!isSignupPage && (
				<Script id="wcs-nasa-script" strategy="lazyOnload">
					{`
          if (!wcs_add) var wcs_add={};
          wcs_add["wa"] = "s_1b6f4943c842";
          if (!_nasa) var _nasa={};
          if(window.wcs){
            wcs.inflow();
            wcs_do(_nasa);
          }
        `}
				</Script>
			)}
		</>
	)
}

MyApp.getInitialProps = async (appContext) => {
	const appProps = await App.getInitialProps(appContext)
	const req = appContext.ctx?.req
	const res = appContext.ctx?.res
	if (!req) return { ...appProps, auth: undefined }
	const { accessToken, session } = getCookies({ req, res })
	let auth = req?.auth

	if (accessToken) {
		try {
			let response = await getMe(accessToken)

			auth = { ...auth, ...response } // auth 정보에 추가
		} catch (e) {
			console.log('error', e)
			console.error('Failed to fetch user data:', e)
		}
	}
	return { ...appProps, auth: auth }
}

export default MyApp
