import useGNBHandler from '@/components/common/GNB/useGNBHandler'
import useLogout from '@/components/common/GNB/useLogout'
import { Hamburger, User } from '@/components/icon/IconList'
import { GNBSideSheet } from '@/components/SideSheets/GNBSideSheet'
import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import {
	EBOOKS_ORDER,
	LOGIN_PAGE,
	MYPAGE_COUPONS,
	MYPAGE_PACKAGES_INDEX_PAGE,
	MYPAGE_PASSES,
	MYPAGE_STORES_PRODUCTS_INDEX,
	REGISTRATION_PAGE,
} from '@/settings/constant'
import { CommonGNB, GNB, GNBMenuIcon } from '@bookips/solvook-ui-library'
import { getCurrentCartQuery } from 'src/api/booksCart'

const GoodnotesGNB = () => {
	const { handleLogout, isLoggedIn } = useLogout()

	const { data: cartCounter } = getCurrentCartQuery({
		params: {
			cartType: 'ebook',
		},
	})

	const { handleDropdownClick, handlePageMove, handleSideMenu, labName } = useGNBHandler()

	return (
		<>
			<CommonGNB
				onClickLogo={() => mixpanelEvent('Logo Clicked')}
				loginButtonGroup={{
					onLoginClick: () => handlePageMove(LOGIN_PAGE, true),
					onSignUpClick: () => handlePageMove(REGISTRATION_PAGE, true),
				}}
				menu={{
					cart: () => handlePageMove(EBOOKS_ORDER, false),
					fixedMobileMenu: {
						loggedIn: {
							onClick: () => handleSideMenu(),
							icon: <GNBMenuIcon Element={Hamburger} />,
							label: '메뉴',
						},
						loggedOut: {
							onClick: () => handleSideMenu(),
							icon: <GNBMenuIcon Element={Hamburger} />,
							label: '메뉴',
						},
					},
					cartBadge: cartCounter?.lineItemsCount,
					dropdown: (
						<GNB.MenuButtonWithDropdown
							label="마이페이지"
							menuItems={[
								...(labName
									? [
											{
												label: '나의 연구소',
												onClick: () => handleDropdownClick(`/@${labName}`),
											},
									  ]
									: []),

								{
									label: '패키지 보관함',
									onClick: () => handleDropdownClick(MYPAGE_PACKAGES_INDEX_PAGE),
								},
								{
									label: '쿠폰 관리',
									onClick: () => handleDropdownClick(MYPAGE_COUPONS),
								},
								{
									label: '이용권 관리',
									onClick: () => handleDropdownClick(MYPAGE_PASSES),
								},
								{
									label: '자료 판매',
									onClick: () =>
										handleDropdownClick(MYPAGE_STORES_PRODUCTS_INDEX),
								},
								{
									label: '로그아웃',
									onClick: () => handleLogout(),
								},
							]}>
							<GNBMenuIcon Element={User} />
						</GNB.MenuButtonWithDropdown>
					),
				}}
				searchTextField={false}
				isLoggedIn={isLoggedIn}
				banner="goodnotes"
				brandSiblings="goodnotes"
				disabledCategory={true}
			/>
			<GNBSideSheet onLogout={handleLogout} />
		</>
	)
}
export default GoodnotesGNB
