import React, { useEffect, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import Icon from '@/components/icon/Icon'
import { ArrowDown, ArrowUp, Sizes } from '@/components/icon/IconList'
import { Checkbox } from 'antd'
import themeGet from '@styled-system/theme-get'
import { SelectedFilterType } from '@/modules/search/store/search.store'
import { renderKoreanFilterName } from '@/components/Search/index'

export interface MobileFilterListItemProps {
	title: string
	filterItem: any
	handleFilter: (filterType: string, id: number | string, value: string) => void
	selectedFilters: SelectedFilterType[]
	openSlider: (isOpen: boolean, value: string) => void
	sliderOpen: { isOpen: boolean; value: string }
}

export const MobileFilterListItem: React.FC<MobileFilterListItemProps> = ({
	title,
	filterItem,
	handleFilter,
	selectedFilters,
	openSlider,
	sliderOpen,
}) => {
	const theme = useTheme()

	const [dropdown, setDropdown] = useState<'folding' | 'expanding'>('folding')
	const [layoutClassName, setLayoutClassName] = useState<'layout-1' | 'layout-2' | 'layout-3'>(
		'layout-2',
	)
	const [listHeight, setListHeight] = useState<number>(0)
	const titleCount = selectedFilters.filter((filter) => filter.key === title).length
	const childRef = React.useRef<HTMLUListElement>(null)

	const foldingClick = () => {
		setDropdown(dropdown === 'folding' ? 'expanding' : 'folding')
		if (dropdown === 'folding') {
			openSlider(true, title)
			return
		}
		openSlider(true, '')
	}
	const layout = () => {
		if (title === 'brands') {
			setLayoutClassName('layout-1')
		} else if (title === 'units') {
			setLayoutClassName('layout-3')
		}
	}
	const onCheckboxChange = (id: number | string, value: string) => {
		handleFilter(title, id, value)
	}
	useEffect(() => {
		layout()
	}, [title])

	useEffect(() => {
		if (dropdown === 'expanding' && childRef.current) {
			setListHeight(childRef.current.scrollHeight)
		}
		if (!sliderOpen.isOpen || title !== sliderOpen.value) {
			setDropdown('folding')
		}
		if (sliderOpen.isOpen && title === sliderOpen.value) {
			setDropdown('expanding')
		}
	}, [dropdown, sliderOpen.isOpen, sliderOpen.value, filterItem])
	return (
		<MobileFilterListItemWrapper>
			<div className={`filter-title-wrapper title-${dropdown}`} onClick={foldingClick}>
				<p>
					{renderKoreanFilterName(title)}
					{titleCount > 0 && <span> {titleCount}</span>}
				</p>
				<Icon
					icon={dropdown === 'folding' ? ArrowDown : ArrowUp}
					color={
						dropdown === 'folding'
							? theme.new.colors.sol_gray_600
							: theme.new.colors.sol_gray_900
					}
					size={Sizes.small}
				/>
			</div>
			<div
				className={'list-wrapper'}
				style={{ height: dropdown === 'expanding' ? listHeight : '0px' }}>
				<ul className={`checkbox-list-wrapper ${layoutClassName}`} ref={childRef}>
					{filterItem.map((item, idx) => {
						if (title === 'brands') {
							return item.values.map((brand, itemIdx) => {
								let isChecked = false
								selectedFilters.map((filter) => {
									if (
										title === filter.key &&
										filter.value === brand.id.toString()
									)
										isChecked = true
								})
								return (
									<li key={itemIdx}>
										<Checkbox
											checked={isChecked}
											onChange={() => onCheckboxChange(brand.id, item.value)}>
											{brand.value}
										</Checkbox>
									</li>
								)
							})
						} else {
							if (!item.value) return
							let isChecked = false
							selectedFilters.map((filter) => {
								if (title === 'units' && filter.value === item.value)
									isChecked = true
								else if (
									title === filter.key &&
									filter.value === item.id.toString()
								)
									isChecked = true
							})
							return (
								<li key={idx}>
									<Checkbox
										checked={isChecked}
										onChange={() => {
											if (title === 'units') {
												onCheckboxChange(item.value, item.value)
											} else onCheckboxChange(item.id, item.value)
										}}>
										{item.value}
									</Checkbox>
								</li>
							)
						}
					})}
				</ul>
			</div>
		</MobileFilterListItemWrapper>
	)
}
const MobileFilterListItemWrapper = styled.div`
	.checkbox-list-wrapper {
		display: flex;
		flex-wrap: wrap;
		background-color: ${(props) => props.theme.new.colors.sol_gray_0};
	}

	.ant-checkbox-wrapper {
		padding: 0 16px;
		min-height: 41px;
		height: fit-content;
		align-items: center;
		margin-inline-start: 0;
		font-size: 14px;
		color: ${(props) => props.theme.new.colors.sol_gray_600};
		width: 100%;

		:hover {
			background-color: rgba(27, 27, 41, 0.03);
		}

		.ant-checkbox {
			top: 0;
		}

		.ant-checkbox + span {
		}
	}

	.ant-checkbox-wrapper-checked {
		.ant-checkbox + span {
			font-weight: 600;
			color: ${themeGet('primary.0', '#000000')};
		}
	}

	.layout-1 {
		li {
			width: 100%;
		}
	}

	.layout-2 {
		li {
			width: 50%;
		}
	}

	.layout-3 {
		li {
			width: 33.3333%;
			@media (max-width: 768px) {
				width: 50%;
			}
		}
	}

	.list-wrapper {
		overflow: hidden;
		transition: height 0.35s ease;
	}
`
