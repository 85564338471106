import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import ReactIFDO from 'react-ifdo'
import { useAuthStore } from '../auth/AuthStore'
import { useIfdoStore } from 'src/store/useIfdoStore'

const useBridgeIfdo = () => {
	const router = useRouter()
	const initialized = useIfdoStore((state) => state.initialized)
	const setInitialized = useIfdoStore((state) => state.setInitialized)

	const { user } = useAuthStore()

	useEffect(() => {
		try {
			ReactIFDO.initialize(process.env.NEXT_PUBLIC_IFDO_GCODE)
			setInitialized(true)
		} catch (error) {
			console.error('Failed to initialize IFDO:', error)
		}
	}, [])

	useEffect(() => {
		if (initialized) {
			ReactIFDO.pageView(router.asPath)
		}
	}, [initialized, router.asPath])

	useEffect(() => {
		if (initialized && user) {
			ReactIFDO._NB_ID = `${user.id}`
			ReactIFDO.strValidNotice('_NB_ID')
		}
	}, [initialized, user])

	return null
}

export default useBridgeIfdo
