import { useRouter } from 'next/router'
import { useSideCategoryMenuStore } from '../../SideSheets/store/useCategoryMenuStore'
import { useCallback, useMemo } from 'react'
import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import useRedirect from '@/hooks/useRedirect'
import { useAuthStore } from '@/lib/auth/AuthStore'
import { useQuery } from '@tanstack/react-query'
import { getLabByAuthorId } from '../../../api/lab'
import {
	EBOOKS_ORDER,
	LIBRARIES_INDEX_PAGE,
	LOGIN_PAGE,
	MYPAGE_COUPONS,
	MYPAGE_PACKAGES_INDEX_PAGE,
	MYPAGE_PAGE,
	MYPAGE_PASSES,
	MYPAGE_STORES_PRODUCTS_INDEX,
	REGISTRATION_PAGE,
	SHOP_CART_PAGE,
	SOLVOOK_WRITE_STUDIO,
} from '@/settings/constant'

const MixPanelPropertyType = {
	[SHOP_CART_PAGE]: {
		name: '장바구니',
		type: 'cart',
	},
	[EBOOKS_ORDER]: {
		name: '장바구니',
		type: 'cart',
	},
	[SOLVOOK_WRITE_STUDIO]: {
		name: '엑스퍼트',
		type: 'expert',
	},
	[LIBRARIES_INDEX_PAGE]: {
		name: '자료보관함',
		type: 'library',
	},
	[LOGIN_PAGE]: {
		name: '로그인',
		type: 'login',
	},
	[REGISTRATION_PAGE]: {
		name: '회원가입',
		type: 'signup',
	},
	[MYPAGE_PAGE]: {
		name: '마이쏠북',
		type: 'mypage',
	},
	[MYPAGE_COUPONS]: {
		name: '쿠폰 관리',
		type: 'mypageCoupons',
	},
	[MYPAGE_PASSES]: {
		name: '이용권 관리',
		type: 'mypagePasses',
	},
	[MYPAGE_STORES_PRODUCTS_INDEX]: {
		name: '자료 판매',
		type: 'mypageStores',
	},
	[MYPAGE_PACKAGES_INDEX_PAGE]: {
		name: '패키지보관함',
		type: 'mypagePackages',
	},
}
const useGNBHandler = () => {
	const { push } = useRouter()
	const { redirectTo } = useRedirect()
	const { user, isLoggedIn } = useAuthStore()
	const { isOpenSideMenu, openSideMenu, closeSideMenu, openMobileSideMenu } =
		useSideCategoryMenuStore()

	const queueMixpanel = useCallback((type: string, name: string) => {
		mixpanelEvent('GNB Button Clicked', {
			'button type': type,
			'button name': name,
		})
	}, [])
	const handleDropdownClick = useCallback(
		(url: string) => {
			const name = url.includes('@') ? '나의 연구소' : MixPanelPropertyType[url]?.name
			queueMixpanel('mypage', name)
			push(url)
		},
		[user],
	)
	const handleCategoryButton = () => {
		if (isOpenSideMenu) closeSideMenu()
		else openSideMenu()
		queueMixpanel('category', '카테고리')
	}
	const handlePageMove = (url: string, redirect: boolean) => {
		queueMixpanel(MixPanelPropertyType[url]?.type, MixPanelPropertyType[url]?.name)
		if (redirect) {
			redirectTo(url)
			return
		}
		push(url)
	}
	const handleSideMenu = () => {
		queueMixpanel('menu', '메뉴')
		openMobileSideMenu()
	}
	const { data: labDataResponse } = useQuery(
		['authorLab', user],
		() => getLabByAuthorId(user?.id),
		{
			enabled: isLoggedIn && !!user?.flags?.is_use_lab,
		},
	)
	const labName = useMemo(() => {
		if (!labDataResponse) return undefined
		return labDataResponse?.lab?.lab_name
	}, [labDataResponse])

	return {
		handleDropdownClick,
		handleCategoryButton,
		handlePageMove,
		isOpenSideMenu,
		handleSideMenu,
		labName,
		queueMixpanel,
	}
}
export default useGNBHandler
