import styled from 'styled-components'
import { IconButton, styled as muiStyled } from '@mui/material'
import { ArrowLeft, Sizes } from '../icon/IconList'
import Icon from '../icon/Icon'

export const SearchPageWrapper = styled.div`
	padding: 0 0 80px;
	min-height: 80vh;
	@media (max-width: 768px) {
		/* background-color: #ffff; */
		.active-tab-wrapper {
			margin: 0 -16px;
			padding: unset;
			flex: 1;
			display: flex;
			align-items: center;
			gap: unset;
			overflow-x: auto;

			li {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 12px 0;

				> div {
					font-size: 16px;
					line-height: 24px;
				}

				&.active {
					padding: 12px 0 10px;
				}
			}
		}
	}
`
export const SearchListWrap = styled.div`
	flex: 1;

	.section-title {
		font-size: 20px;
		font-weight: bold;
	}
`
export const SelectingArea = styled.div`
	margin-top: 36px;
	border-radius: 6px;
	box-shadow: 3px 3px 12px 0px rgba(28, 31, 46, 0.06);
	padding: 24px;
	background-color: white;
	@media (max-width: 768px) {
		box-shadow: none;
		border-bottom: 8px solid ${(props) => props.theme.new.colors.sol_gray_50};
		border-radius: 6px 6px 0 0;
		margin: 0 -16px;
		padding: 16px 16px 0;
	}

	.filter-area {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 24px;

		@media (max-width: 768px) {
			gap: 16px;
			margin: 0 -16px;
			padding-left: 16px;
			padding-bottom: 16px;
		}
	}
`
export const FilterArea = styled.div`
	display: block;
`
export const MobileSliderArea = styled.div`
	display: none;
	@media (max-width: 768px) {
		display: block;
		margin: 0 -16px;
	}
`
export const ActiveTabLabel = styled.div`
	display: flex;
	gap: 8px;
	font-size: 28px;
	align-items: center;

	> span {
		padding: 0 8px;
		border-radius: 6px;
		background-color: ${(props) => props.theme.new.colors.sol_gray_50};
		font-size: 14px;
		font-weight: 600;
		line-height: 22px;
		height: 22px;
	}
`

export const FilterTitle = muiStyled('p')(({ theme }) => {
	return {
		display: 'flex',
		alignItems: 'center',
		paddingRight: '16px',
		height: '38px',
		fontSize: '16px',
		fontWeight: 600,
		lineHeight: '24px' /* 150% */,
		letterSpacing: '-0.16px',
	}
})
export const ButtonWithIcon = ({ onClick }) => {
	return (
		<IconButton
			onClick={onClick}
			color="primary"
			sx={(theme) => {
				return {
					p: '8px',
					height: 'fit-content',
				}
			}}
			aria-label="directions">
			<Icon icon={ArrowLeft} size={Sizes.xmedium} />
		</IconButton>
	)
}
export const renderKoreanFilterName = (key: string): string => {
	let result = ''
	switch (key) {
		case 'genres':
			result = '장르'
			break
		case 'eras':
			result = '시대'
			break
		case 'use_books':
		case 'relatedBooks':
			result = '이용교재'
			break
		case 'brands':
		case 'authors':
			result = '제작자'
			break
		case 'units':
			result = '단원'
			break
		case 'publishers':
		case 'publishers[]':
		case 'publisher':
			result = '출판사'
			break
		case 'data_types':
		case 'dataTypes':
			result = '자료유형'
			break
		case 'content_types':
		case 'contentTypes':
			result = '학습 내용'
			break
		case 'curriculums':
		case 'curriculums[]':
		case 'curriculum':
			result = '학년'
			break
		case 'subjects':
		case 'subjects[]':
		case 'subject':
			result = '과목'
			break
		// 믹스패널 때문에 추가
		case '추천 카테고리':
			result = '추천 카테고리'
			break
		case '편집가능':
			result = '편집가능'
			break
		case 'tickets':
			result = '이용권'
			break
		default:
			result = key
			break
	}

	return result
}
