import { SideSheetStep } from '@/components/SideSheets/GNBSideSheet'
import useRedirect, { REDIRECT_QUERY } from '@/hooks/useRedirect'
import { useAuthStore } from '@/lib/auth/AuthStore'
import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import {
	LIBRARIES_INDEX_PAGE,
	LOGIN_PAGE,
	MYPAGE_PASSES,
	PASSES_LANDING,
	REGISTRATION_PAGE,
	SOLVOOK_WRITE_STUDIO,
} from '@/settings/constant'
import { Button as SolvookButton } from '@bookips/solvook-ui-library'
import { Button, Divider } from 'antd'
import Link from 'next/link'
import { useRouter } from 'next/router'
import styled, { useTheme } from 'styled-components'
import Icon from '../icon/Icon'
import { ArrowLeft, ArrowRight, Close1_5px, Library, Sizes, Studio, User } from '../icon/IconList'
import { MembershipStatusLinkWrapper } from '../Landing/PassPromotion'
import { useTooltip } from '../common/GNB/useTooltip'
import { GuidTooltip } from '../tooltip/GuidTooltip'

export const SideSheetHeader = ({
	title,
	onClose,
	currentStep,
	goToPrevStep,
}: {
	title: string
	onClose: () => void
	currentStep?: SideSheetStep
	goToPrevStep?: () => void
}) => {
	const theme = useTheme()
	return (
		<SideSheetHeaderContainer>
			<p>
				{!!goToPrevStep && (
					<Icon
						onClick={goToPrevStep}
						icon={ArrowLeft}
						size={Sizes.xmedium}
						color={theme.new.colors.sol_gray_900}
					/>
				)}
				{title}
			</p>
			<Icon
				className={'close-btn'}
				onClick={onClose}
				icon={Close1_5px}
				size={Sizes.xmedium}
				color={theme.new.colors.sol_gray_900}
			/>
		</SideSheetHeaderContainer>
	)
}
export const SideSheetHeaderContainer = styled.div`
	display: flex;
	padding: 16px 20px;
	justify-content: space-between;
	align-items: center;
	font-size: 16px;
	font-weight: 600;
	line-height: 26px;

	p {
		align-items: center;
		display: flex;

		i {
			margin-right: 12px;
		}
	}
`
export const SideSheetUtils = () => {
	const { isLoggedIn } = useAuthStore()
	const theme = useTheme()
	return (
		<SideSheetUtilContainer>
			{isLoggedIn ? <LoginMenu /> : <LogoutMenu />}
			<SideSheetPromotionLink href={PASSES_LANDING}>
				<p>이용권 구매하기</p>
				<Icon
					icon={ArrowRight}
					size={Sizes.small}
					color={theme.new.colors.sol_indigo_500}
				/>
			</SideSheetPromotionLink>
		</SideSheetUtilContainer>
	)
}
export const SideSheetUtilContainer = styled.div`
	padding: 12px 20px 20px 20px;
	display: flex;
	flex-direction: column;
	gap: 8px;
`
const LogoutMenu = () => {
	const router = useRouter()
	const currentPathname = router.asPath
	const { redirectToLogin } = useRedirect()
	const sideSheetMixpanel = (name: string, link: string, type: string) => {
		mixpanelEvent('Side Sheets Button Clicked', {
			'Button Type': type,
			'Button Name': name,
			'Button Url': link,
		})
	}
	return (
		<LogoutMenuWrapper>
			<SolvookButton
				variant={'strokeSecondary'}
				onClick={() => {
					sideSheetMixpanel('login', LOGIN_PAGE, '로그인')
					redirectToLogin()
				}}
				label="로그인"
			/>
			<SolvookButton
				variant={'soft'}
				onClick={() => {
					sideSheetMixpanel('signup', REGISTRATION_PAGE, '회원가입')
					router.push({
						pathname: `${REGISTRATION_PAGE}`,
						query: currentPathname.length > 1 && { [REDIRECT_QUERY]: currentPathname },
					})
				}}
				label="회원가입"
			/>
		</LogoutMenuWrapper>
	)
}
const LoginMenu = () => {
	const theme = useTheme()
	const loginMenu = [
		{
			title: '자료 보관함',
			icon: Library,
			link: LIBRARIES_INDEX_PAGE,
			type: 'library',
		},
		{
			title: '엑스퍼트',
			icon: Studio,
			link: SOLVOOK_WRITE_STUDIO.concat('/pre-exam'),
			type: 'expert',
		},
		{
			title: '마이페이지',
			icon: User,
			link: MYPAGE_PASSES,
			type: 'mypage',
		},
	]
	const { isTooltipVisible, handleTooltip } = useTooltip({
		cookieName: 'expertTooltip',
		day: 1,
	})
	return (
		<LoginMenuContainer>
			{loginMenu.map((menu, index) => {
				return (
					<SideSheetMenuLink
						key={index}
						href={menu.link}
						onClick={() => {
							mixpanelEvent('Side Sheets Button Clicked', {
								'Button Type': menu.type,
								'Button Name': menu.title,
								'Button Url': menu.link,
							})
							if (menu.type === 'expert') handleTooltip()
						}}>
						<Icon
							icon={menu.icon}
							size={Sizes.medium}
							color={theme.new.colors.sol_gray_700}
						/>
						{menu.type === 'expert' && isTooltipVisible && (
							<GuidTooltip isVisibleTooltip={isTooltipVisible} placement={'top'}>
								우리학교 기출 적중
							</GuidTooltip>
						)}
						<p>{menu.title}</p>
					</SideSheetMenuLink>
				)
			})}
		</LoginMenuContainer>
	)
}
export const MenuListItem = ({ label, onClick }: { label: string; onClick?: () => void }) => {
	return (
		<MenuListItemContainer onClick={onClick}>
			<p>{label}</p>
			<Icon icon={ArrowRight} size={Sizes.xmedium} />
		</MenuListItemContainer>
	)
}

export const MenuListItemContainer = styled.li`
	padding: 16px 20px;
	display: flex;
	justify-content: space-between;
	color: ${(props) => props.theme.new.colors.sol_gray_700};
	font-size: 16px;
	font-weight: 600;
	line-height: 1.5;
	letter-spacing: -0.16px;
	cursor: pointer;
	word-break: break-word;
	align-items: center;

	i {
		margin-left: 20px;
	}

	p {
		color: ${(props) => props.theme.new.colors.sol_gray_700};
	}
`

export const MenuListContainer = styled.div`
	/* margin-top: 24px; */
	height: 100%;
	margin: 0;

	&.depth-two-container {
		height: calc(100vh - 58px);
		max-height: calc(var(--svh, 1svh) * 100 - 58px);
	}
`
export const MenuListTitle = styled.p`
	color: ${(props) => props.theme.new.colors.sol_gray_300};
	font-size: 14px;
	font-weight: 600;
	line-height: 22px;
	letter-spacing: -0.14px;
	padding: 0 20px;
`

const LoginMenuContainer = styled.div`
	display: flex;
	width: 100%;
	gap: 8px;
`
const SideSheetMenuLink = styled(Link)`
	display: flex;
	border: 1px solid ${(props) => props.theme.new.colors.sol_gray_100};
	border-radius: 6px;
	padding: 8px 16px;
	width: 100%;
	align-items: center;
	flex-direction: column;
	gap: 2px;
	justify-content: center;

	text-align: center;

	:hover {
		color: unset;
	}

	p {
		font-size: 14px;
		line-height: 22px;
		font-weight: 600;
		letter-spacing: -0.14px;
	}
`
export const LogoutMenuWrapper = styled.div`
	display: flex;
	gap: 8px;

	> button {
		width: 100%;
		padding: 12px 20px;
		font-size: 16px;
		line-height: 1.5;
		transition: all 0.3s;

		:first-child {
			border: 1px solid ${(props) => props.theme.new.colors.sol_gray_100};

			:hover {
				color: unset;
				background-color: ${(props) => props.theme.new.colors.sol_gray_100};
			}
		}
	}
`

export const LogoutButton = styled(Button)`
	//position: sticky;
	display: flex;
	align-items: center;
	width: 100%;
	bottom: 0;
	margin-top: 80px;
	border: none;
	text-align: left;
	padding: 13px 20px;
	height: 48px;
	color: ${(props) => props.theme.new.colors.sol_gray_500};
	line-height: 22px; /* 157.143% */
	border-top: 1px solid ${(props) => props.theme.new.colors.sol_gray_100};

	i {
		margin-right: 8px;
	}
`
export const SideSheetPromotionLink = styled(MembershipStatusLinkWrapper)`
	border-radius: 6px;
	justify-content: space-between;
	margin-bottom: 0;
	padding: 9px 16px;
`
export const SideSheetContentsContainer = styled.div`
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);

	display: flex;
	flex-direction: column;
	justify-content: space-between;
`
export const StepDivider = styled(Divider)`
	margin: 0;
`
