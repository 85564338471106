import { api, IQueryResponse, otherApiClient } from '.'

export interface IProduct {
	id: number
	uid?: string
	source_id: number
	source_type: string
	source?: HandoutDTO | WorkbookDTO | TextbookDTO | null
	slug: string
	category: string
	sub_category: string
	title: string
	description: string
	product_type: string
	price: number
	available: boolean
	metafields_global_title_tag: string
	metafields_global_description_tag: string
	published_scope: number
	template_suffix: number
	vendor?: string
	vendor_id?: number
	author?: string
	author_id?: number
	content: string
	tags: string[]
	cover_img: string
	thumb_img: string
	preview_img: string[]
	options: PGJsonOptions
	store_id?: number
	status: string
	product_variant_count: number
	published_at: Date
	created_at: Date
	updated_at: Date
	deleted_at?: Date
	product_variants?: ProductVariantDTO[]
	manual_inactive?: boolean // 출처라이선스 기간만료의 이슈로 수동으로 내려간 상품들의 구분값
	flags?: {
		[key: string]: boolean
	}
	filter_category?: number
	filter_category_name?: string
	data_type?: number[]
	rating?: string
	is_coupon_available?: boolean
}

export type PGJsonOptions = {
	[key: string]: string | number | object | string[] | number[]
}

export interface HandoutDTO {
	id: number
	uid?: string

	product_id?: number // product와 어떻게 연결될 지 몰라 일단 추가했다고 했었음.
	bundle_id?: number // handout 생성 시 단원추가로 함께 일괄 생성한 경우 공통된 아이디를 가지기 위해 추가

	title: string // 제목
	author: string // 저자명
	author_id: number // 자료저자의 user_id
	genre?: string // 장르
	era?: string // 시대
	subject: Subjects // 과목
	curriculum: Curriculums // 교육과정
	grade: Grades // 학년

	price_lite: number // 라이트 요금제 가격
	price_basic: number // 기본 요금제 가격
	price_pro: number // 프로 요금제 가격

	base_source_id: number // 해당 자료를 만들기 위해 출처 라이센스로 선택된 Textbook id
	base_source_type: string
	publisher?: string
	based_textbook_thumb?: string //옵션널로 CRUD에선 사용안함 product 조회용
	based_textbook_title?: string //옵션널로 CRUD에선 사용안함 product 조회용
	images?: {
		promotions: HandoutPromotionImageDTO[]
		previews: HandoutPreviewImageDTO[]
	} // 상품설명 이미지들

	unit: string // 자료 생성 시 선택한 단원
	category: Categories // 자료 유형
	question_number: number // 문항수
	detail?: string // 자료 상세 정보

	thumb_img?: string // 썸네일 이미지
	previews?: string // 미리보기 이미지	- 추가보완이 필요함
	keywords?: string[] // 자료 생성 시 저장할 키워드. 최대 10개

	// images?: object | null // 상세정보에 노출될 상품 홍보 이미지 - 추가보완이 필요함 - images 필드가 아예 없어지고 attachments가 images 필드가 되야할 것 같음

	options?: object
	object?: {
		isParsedByMl?: boolean
	}
	created_at?: Date
	updated_at?: Date

	published_at?: Date
	deleted_at?: Date

	old_id?: number // v1에서 가져온 자료의 id
	migration_temp?: boolean // v1에서 가져온 보관용 데이터

	attachments?: HandoutAttachmentDTO[]

	// 20230717 data_type - 자료유형 3개 복수가능, content_type - 내용체계 12개 복수가능
	data_type?: Categories[]
	content_type?: ContentTypes[]
	studio_version?: string // 스튜디오 버전
}

export interface HandoutAttachmentDTO {
	id: number
	uid?: string

	handout_id: number // 첨부파일이 속한 핸드아웃의 id
	storage_type: string // 첨부파일의 저장소 타입

	url?: string // 첨부파일의 url
	path?: string // 첨부파일의 업로드 된 경로
	filename?: string // 첨부파일의 원본이름

	content_type?: string // 첨부파일의 컨텐츠 타입
	size?: number // 첨부파일의 크기

	options?: object // 옵션값

	created_at: Date
	updated_at?: Date
}

export interface ProductVariantDTO {
	id: number
	uid: string
	sku?: string

	product_id: number

	title: string
	description: string

	price: number

	// 기존의 level 을 대체
	plan?: string

	available?: boolean

	options?: PGJsonOptions
}

export interface HandoutPreviewImageDTO {
	url: string
	path: string
	filename: string
	page_num: number
}

export interface HandoutPromotionImageDTO {
	order: number
	url: string
	path: string
	filename: string
	handout_attachment_id: number
}

export interface WorkbookDTO {
	id: number
	uid?: string

	product_id?: number // product랑 어떻게 연결될지 몰라서 넣었다고 했음.
	publisher_id?: number // 참고서 출판사 publishers id
	author: string // 저자
	title: string // 교과서 제목
	subject: Subjects // 과목
	curriculum: Curriculums // 교육과정
	grade: Grades // 학년
	origin_price: number // 원가
	price_lite: number // 라이트 요금제 가격
	price_basic: number // 기본 요금제 가격
	price_pro: number // 프로 요금제 가격
	thumb_img?: string // 썸네일 이미지
	detail?: string // 상세 설명
	isbn10: string // 국제도서표준코드? 10자리와 13자리로 관리. 10자리는 2007년 이전에 발행된 책에 사용
	isbn13: string // 국제도서표준코드 13자리
	previews?: string[] // 미리보기 이미지들
	images?: string[] // 이미지들
	keywords?: string[] // 키워드들
	options?: object // 옵션들
	created_at?: Date
	updated_at?: Date
	published_at?: Date
	deleted_at?: Date
	old_id?: number // 기존 데이터베이스의 id
	migration_temp?: object // migration을 위한 임시 필드
	sub_items?: WorkbookSubItem[]
	referenced_books?: ReferencedBookDTO[]
}

export interface ReferencedBookDTO {
	id: number
	title: string
	auth: string
}

export interface WorkbookSubItem {
	id: number
	uid?: string
	workbook_id?: number // subitem이 속한 workbook의 id
	type?: number //10 학생용 20 선생님용 40 답안지
	main_item: boolean // 주교재여부를 체크
	title: string // 교과서 제목
	options?: object // 옵션들
	created_at?: Date
	updated_at?: Date
	published_at?: Date
	deleted_at?: Date
	old_id?: number // 기존 데이터베이스의 id
	migration_temp?: object // migration을 위한 임시 필드
}

export interface TextbookDTO {
	id: number
	uid?: string
	product_id?: number // product랑 어떻게 연결될지 몰라서 넣었다고 했음.
	publisher_id?: number // 교과서 출판사 publishers id
	author: string // 저자
	title: string // 교과서 제목
	subject: Subjects // 과목
	curriculum: Curriculums // 교육과정
	grade: Grades // 학년
	era?: string // 시대
	genre?: string // 장르
	origin_price: number // 원가
	price_lite: number // 라이트 요금제 가격
	price_basic: number // 기본 요금제 가격
	price_pro: number // 프로 요금제 가격
	thumb_img?: string // 썸네일 이미지
	detail?: string // 상세 설명
	isbn10: string // 국제도서표준코드? 10자리와 13자리로 관리. 10자리는 2007년 이전에 발행된 책에 사용
	isbn13: string // 국제도서표준코드 13자리
	previews?: string[] // 미리보기 이미지들
	images?: object // 이미지들
	keywords?: string[] // 키워드들
	options?: object // 옵션들
	created_at?: Date
	updated_at?: Date
	published_at?: Date
	deleted_at?: Date
	old_id?: number // 기존 데이터베이스의 id
	migration_temp?: object // migration을 위한 임시 필드
	sub_items?: TextbookSubItem[]
	referenced_books?: ReferencedBookDTO[]
}

export interface TextbookSubItem {
	id: number
	uid?: string

	textbook_id?: number // subitem이 속한 textbook의 id
	//10 학생용 20 선생님용 40 답안지
	type?: number

	main_item: boolean // 주교재여부를 체크

	title: string // 교과서 제목

	options?: object // 옵션들

	created_at: Date
	updated_at?: Date

	published_at?: Date
	deleted_at?: Date

	old_id?: number // 기존 데이터베이스의 id
	migration_temp?: object // migration을 위한 임시 필드
}

export const SourceTypeWithString = {
	textbook: '교과서 라이선스',
	workbook: '참고서 라이선스',
	handout: '수업자료',
	work: '문학 라이선스',
	all: '전체',
	aladinbook: '도서',
	ebook: '굿노트6 eBook',
	package: '이용권',
	ebookUniv: '굿노트6 대학생용 eBook', //대학생용 이북
} as const
export type SourceType = keyof typeof SourceTypeWithString
// EN : 영어, KO : 한국어, MA : 수학, SC : 과학, SO : 사회, SL : 제2외국어, KH : 한국사, ET : 기타
export const Subjects = {
	EN: 'EN',
	KO: 'KO',
	MA: 'MA',
	SO: 'SO',
	SC: 'SC',
	SL: 'SL',
	KH: 'KH',
	HI: 'HI',
	ET: 'ET',
} as const
export type Subjects = (typeof Subjects)[keyof typeof Subjects]

export function isSubjects(value: string | string[] | null): value is Subjects {
	return !!Object.values(Subjects).find((v) => v === value)
}

//구매가능한 과목 리스트 (검색, 과목탭에서 사용)
export const purchasableSubjectsList: () => Array<{
	key: Subjects
	label: SubjectsWithString
}> = () => {
	return [Subjects.EN, Subjects.KO, Subjects.MA, Subjects.SC, Subjects.SO, Subjects.HI].map(
		(subject) => {
			return {
				key: subject,
				label: SubjectsWithString[subject],
			}
		},
	)
}
// 10 : 초등, 20 : 중등, 30 : 고등
export type Curriculums = 10 | 20 | 30 | null
// 10 : 1학년, 20 : 2학년, 30 : 3학년, 40 : 4학년, 50 : 5학년 , 60 : 6학년
export type Grades = 10 | 20 | 30 | 40 | 50 | 60 | null
export type Categories = 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90 | 100 | 110 | 120 | 130 | null
export type CategoriesWithString = (typeof DataTypesWithString)[keyof typeof DataTypesWithString]
export const DataTypesWithString = {
	10: '기출예상문제',
	20: '단계별복습',
	30: '변형문제',
	40: '본문분석',
	50: '워크북',
	60: '작품분석',
	70: '어법어휘',
	80: '예상문제',
	90: '모의고사',
	100: '개념완성',
	110: '유형훈련',
	120: '직보/파이널',
	130: '개념정리',
} as const
export const CurriculumsWithString = {
	10: '초등',
	20: '중등',
	30: '고등',
	40: '대학생',
	null: '',
} as const
export const GradesWithString = {
	// 10 : 1학년, 20 : 2학년, 30 : 3학년, 40 : 4학년, 50 : 5학년 , 60 : 6학년
	10: '1학년',
	20: '2학년',
	30: '3학년',
	40: '4학년',
	50: '5학년',
	60: '6학년',
	null: '',
} as const
export type CurriculumsWithString =
	(typeof CurriculumsWithString)[keyof typeof CurriculumsWithString]

export const SubjectsWithString = {
	EN: '영어',
	KO: '국어',
	MA: '수학',
	SO: '사회',
	SC: '과학',
	SL: '제2외국어',
	KH: '한국사',
	HI: '역사',
	ET: '기타',
	ALL: '전과목',
	null: '',
} as const
export type SubjectsWithString = (typeof SubjectsWithString)[keyof typeof SubjectsWithString]
export type ContentTypes = 11 | 12 | 13 | 14 | 21 | 22 | 23 | 24 | 31 | 32 | 33 | 34 | null
export const ContentTypesWithString = {
	11: '어휘 정리',
	12: '문법 정리',
	13: '표현 정리',
	14: '이해추론 정리',
	21: '어휘 훈련',
	22: '문법 훈련',
	23: '표현 훈련',
	24: '이해추론 훈련',
	31: '어휘 문제풀이',
	32: '문법 문제풀이',
	33: '표현 문제풀이',
	34: '이해추론 문제풀이',
} as const
export type ContentTypesWithString =
	(typeof ContentTypesWithString)[keyof typeof ContentTypesWithString]

export interface IGetProductRes extends IQueryResponse {
	product: IProduct
}

interface IGetProduct {
	id: string
}

export interface IRelatedProduct {
	id: string
	title: string
	thumbImg: string
	price: number
	author: string
	unit: string
	previewImgs: {
		url: string
		path: string
		pageNum: number
	}[]
	hasAlreadyOwned?: boolean
}

export interface IGetRelatedProductsRes extends IQueryResponse {
	id: string
	hasAlreadyOwned?: boolean
	data: Array<IRelatedProduct>
}

export const getProduct = async (query: IGetProduct) => {
	const { data } = await api.get<IGetProductRes>(`/products/${query.id}`)
	return data
}

export interface PostUseMembershipRequest {
	productIds: Array<string>
	membershipId: string
}

export const postUseMembership = async (param: PostUseMembershipRequest) => {
	const { data } = await otherApiClient.market.post('/products/use-as-membership', {
		membershipIdToProductIds: {
			[param.membershipId]: param.productIds,
		},
	})
	return data
}
export const getRelatedProducts = async (id: string) => {
	const { data } = await otherApiClient.market<IGetRelatedProductsRes>(`/products/${id}/relation`)
	return data
}

export interface getMembershipAvailableResponse {
	userId: number
	data: Array<{
		membershipId: string
		productId: string
		usable: boolean
		unUsableReasons: Array<string>
	}>
}

export const getMembershipAvailable = async ({
	membershipId,
	productIds,
}: PostUseMembershipRequest) => {
	const { data } = await otherApiClient.market.post<getMembershipAvailableResponse>(
		`/products/membership-available`,
		{
			membershipIdToProductIds: {
				[membershipId]: productIds,
			},
		},
	)
	return data
}
