import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

export interface IfdoState {
	initialized: boolean
}
export interface IfdoActions {
	setInitialized: (initialized: boolean) => void
}
export const useIfdoStore = create<IfdoState & IfdoActions>()(
	devtools((set) => ({
		initialized: false,
		setInitialized: (initialized) => set(() => ({ initialized })),
	})),
)
